import { useFormField, useBooleanFormField } from '@kaliber/forms'
import { Dropdown, DropdownForm } from '/features/buildingBlocks/Dropdown'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'
import formErrorIcon from '/images/icons/form-error.raw.svg'
import iconClose from '/images/icons/close.raw.svg'

import styles from './FormField.css'

export const formFieldRenderers = {
  TEXTAREA: FormFieldTextarea,
  RADIO: FormFieldRadioGroup,
  CHECKBOX: FormFieldCheckbox,
  INPUT_TEXT: FormFieldText,
  MULTI_SELECT: FormFieldCheckboxGroup,
  SINGLE_SELECT: FormFieldSelect,
}

export function FormFieldCheckboxBasic({ onChange, value, name, children, required = undefined }) {
  return (
    <FormFieldBase
      className={styles.componentCheckboxBasic}
      {...{ required, name }}
    >
      <Checkbox
        eventHandlers={{ onChange: e => onChange(e.target.checked) }}
        {...{ name, value, children }}
      />
    </FormFieldBase>
  )
}

export function FormFieldCheckboxConsent({ field, children, required = undefined }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase
      className={styles.componentCheckboxConsent}
      error={showError && error}
      {...{ required, name }}
    >
      <Checkbox {...{ name, value, eventHandlers, children }} />
    </FormFieldBase>
  )
}

export function FormFieldCheckbox({ field, label, required = undefined }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase
      className={styles.componentCheckbox}
      error={showError && error}
      {...{ required, name }}
    >
      <Checkbox {...{ name, value, eventHandlers }}>
        {label}{required && '*'}
      </Checkbox>
    </FormFieldBase>
  )
}

function Checkbox({ children, name, value, eventHandlers }) {
  return (
    <div className={styles.componentCheckbox}>
      <label htmlFor={name} className={styles.checkboxLabel}>{children}</label>
      <input
        id={name}
        type='checkbox'
        className={styles.inputCheckbox}
        aria-describedby={`${name}-description ${name}-error`}
        checked={value}
        {...{ name, value }}
        {...eventHandlers}
      />
    </div>
  )
}

export function FormFieldSelectLightSimple({ name, value, options, placeholder, eventHandlers, label = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldSelectBase
      {...{ name, value, label, placeholder, description, required, options, eventHandlers }}
    />
  )
}

export function FormFieldSelect({ field, label, options, placeholder = undefined, description = undefined, required = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { showError, error, value } = state

  return (
    <FormFieldSelectBase
      {...{ name, showError, error, value, label, placeholder, description, required, options, eventHandlers }}
    />
  )
}

function FormFieldSelectBase({
  name,
  value,
  label,
  placeholder,
  description,
  required,
  options,
  eventHandlers,
  showError = undefined,
  error = undefined
}) {
  return (
    <FormFieldBase
      error={showError && error}
      className={styles.componentSelectBase}
      {...{ label, placeholder, description, name, required }}
    >
      <DropdownForm
        describedBy={`${name}-description`}
        {...{ name, value, options }}
        {...eventHandlers}
      />
    </FormFieldBase>
  )
}

export function FormFieldTextarea({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { showError, error } = state

  return (
    <FormFieldBase
      className={styles.componentTextarea}
      error={showError && error}
      {...{ field, label, placeholder, description, required, name }}
    >
      <textarea
        id={name}
        aria-describedby={`${name}-description ${name}-error`}
        className={cx(styles.formFieldTextarea, showError && styles.hasError)}
        value={state.value}
        {...{ placeholder }}
        {...eventHandlers}
        rows='5'
      />

    </FormFieldBase>
  )
}

export function FormFieldEmail({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { showError, error, value } = state

  return (
    <FormFieldBase
      className={styles.componentEmail}
      {...{ field, label, placeholder, description, required, name }}
      error={showError && error}
    >
      <InputTextBase className={styles.formFieldEmail} type='email' {...{ field, placeholder, name, value, eventHandlers, showError }} />
    </FormFieldBase>
  )
}

export function FormFieldTextLightSimple({ placeholder, name, value, eventHandlers }) {
  return <InputTextBase className={styles.componentTextLightSimple} type='text' {...{ placeholder, name, value, eventHandlers }} />
}

export function FormFieldText({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { showError, error, value } = state

  return (
    <FormFieldBase
      className={styles.componentText}
      {...{ field, label, placeholder, description, required, name }}
      error={showError && error}
    >
      <InputTextBase className={styles.formFieldText} type='text' {...{ placeholder, name, value, eventHandlers, showError }} />
    </FormFieldBase>
  )
}

export function FormFieldCheckboxGroup({ label, options, field, required = undefined, disabled = undefined }) {
  const { name, state: { value, showError, error }, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)

  return (
    <FieldsetAndError
      className={styles.componentCheckboxGroup}
      error={showError && error}
      {...{ label, name, required }}
    >
      <div className={styles.radioGroupChildrenContainer}>
        {options.map((option, i) => {
          const id = `${name}__${i}`

          return (
            <div className={styles.checkboxOption} key={id}>
              <label htmlFor={id} className={styles.checkboxLabel}>{option.label}</label>
              <input
                type='checkbox'
                value={option.value}
                className={styles.inputCheckbox}
                aria-describedby={`${name}-description ${name}-error`}
                checked={Array.isArray(value) && value.includes(option.value)}
                onChange={handleChangeFor(option.value)}
                {...eventHandlers}
                {...{ name, disabled, required, id }}
              />
            </div>
          )
        })}
      </div>
    </FieldsetAndError>
  )

  function handleChangeFor(changedValue) {
    return e => {
      const newValue =
        !Array.isArray(value) ? [changedValue] :
        value.includes(changedValue) ? value.filter(x => x !== changedValue) :
        value.concat(changedValue)

      onChange(newValue)
    }
  }
}

export function FormFieldRadioGroup({ label, options, field, required = undefined, disabled = undefined }) {
  const { name, state: { value, showError, error }, eventHandlers } = useFormField(field)

  return (
    <FieldsetAndError
      className={styles.componentRadioGroup}
      error={showError && error}
      {...{ label, name, required }}
    >
      <div className={styles.radioGroupChildrenContainer}>
        {options.map((option, i) => {
          const id = `${name}_${i}`
          return (
            <div className={styles.radioOption} key={id}>
              <label htmlFor={id} className={styles.radioLabelElement}>{option.label}</label>
              <input
                id={name}
                type='radio'
                value={option.value}
                checked={value === option.value}
                className={styles.inputRadioElement}
                aria-describedby={`${name}-description ${name}-error`}
                {...{ name, id, disabled, required }}
                {...eventHandlers}
              />
            </div>
          )
        })}
      </div>
    </FieldsetAndError>
  )
}

export function FormFieldFile({ field, inputLabel, label, accept, required = undefined }) {
  const { name, state, eventHandlers: { onChange, eventHandlers } } = useFormField(field)
  const { value, showError, error } = state

  return (
    <FormFieldBase
      className={styles.componentFile}
      error={showError && error}
      {...{ field, label, required, name }}
    >
      <div className={cx(styles.formFieldFile, showError && styles.hasError)} >
        {value ?
          <button className={styles.inputFileClose} onClick={_ => onChange(null)} data-x='remove-file'>
            {value.name}
            <Icon icon={iconClose} layoutClassName={styles.iconLayout} />
          </button>
          :
          <div className={styles.layoutInputFile}>
            <input
              type='file'
              id={name}
              {...{ accept }}
              className={styles.inputFile}
              onChange={e => {
                onChange(e.currentTarget.files[0])
              }}
              {...eventHandlers}
            />
            <label
              htmlFor={name}
              data-x='select-file'
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className={styles.inputFileLabel}
            >
              <span>{`${inputLabel}${required ? '*' : ''}`}</span>
            </label>
          </div>
        }
      </div>
    </FormFieldBase>
  )

  function handleDragOver(e) {
    e.preventDefault()
  }

  function handleDrop(e) {
    e.preventDefault()

    if (e.dataTransfer.items) {
      const [item] = e.dataTransfer.items ?? []

      if (item && item.kind === 'file') {
        onChange(item.getAsFile())
      }
    }
  }
}

function FormFieldBase({ className, name, children, label = undefined, error = undefined, description = undefined, required = undefined }) {
  return (
    <div className={cx(styles.componentBase, error && styles.hasError, className)}>
      {label && <Label htmlFor={name} {...{ label, required }} layoutClassName={styles.labelLayout} />}
      {children}
      {description && <Description id={`${name}-description`} {...{ name, description }} />}
      {error && <Error id={`${name}-text-field-error`} layoutClassName={styles.errorLayout} {...{ name, error }} />}
    </div>
  )
}

function FieldsetAndError({ className, label, description = undefined, children, name, error, required = undefined }) {
  return (
    <fieldset className={cx(styles.componentFieldsetAndError, className)}>
      {label && (
        <legend className={styles.fieldsetLabel}>
          {label} {required && '*'}
        </legend>
      )}

      {children}

      {description && <Description id={`${name}-description`} {...{ name, description }} />}
      {error && <Error id={`${name}-text-field-error`} layoutClassName={styles.errorLayout} {...{ name, error }} />}
    </fieldset>
  )
}

function InputTextBase({ className, type, placeholder, name, value, eventHandlers, showError = undefined }) {
  return (
    <input
      id={name}
      aria-describedby={`${name}-description ${name}-error`}
      className={cx(styles.componentInputTextBase, className, showError && styles.hasError)}
      {...{ type, placeholder, value }}
      {...eventHandlers}
    />
  )
}

function Label({ label, htmlFor, required = undefined, layoutClassName = undefined }) {
  return (
    <label className={cx(styles.componentLabel, layoutClassName)} {...{ htmlFor }}>
      <span className={styles.innerLabel}>
        {label}{required && '*'}
      </span>
    </label>
  )
}

function Description({ id, description }) {
  return <span className={styles.componentDescription} {...{ id }}>{description}</span>
}

function Error({ id, error, layoutClassName }) {
  const { __ } = useTranslate()

  const errorMessages = {
    required: _ => __`form-validation-required`,
    email: _ => __`form-validation-email`,
    noEmailAccents: _ => __`form-validation-email-noEmailAccents`,
    checked: _ => __`form-validation-checked`,
    linkedInUrl: _ => __`form-validation-linkedInUrl`,
    maxLength: x => __({ length: x })`form-validation-maxlength`,
    fileSize: x => __({ fileSize: bytesToMb(x) })`form-validation-fileSize`,
    fileExtension: extensions => {
      return __({ fileExtensions: readable(extensions) })`form-validation-fileExtension`

      function readable(array) {
        const [last, ...rest] = array.slice().reverse()
        return `${rest.reverse().join(', ')} or ${last}`
      }
    },
  }

  return (
    <>
      <div className={cx(styles.error, layoutClassName)} {...{ id }}>{errorMessages[error.id](...error.params)}</div>
      <div className={styles.errorIconContainer}>
        <Icon icon={formErrorIcon} />
      </div>
    </>
  )
}

function bytesToMb(bytes) {
  return bytes / (1024 * 1024)
}
